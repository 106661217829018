import ApiService from "./api.service";

export const GET_STATISTICS = "getStatistic"
export const SET_STATISTICS = "setStatistic"
export const SET_STATISTICS_LOADING = "setStatisticsLoading"

const state = {
    statistics: {},
    isLoading: false
};

const getters = {
    getStatistics(state) {
        return state.statistics
    }
};

const actions = {
    [GET_STATISTICS]({commit}, params) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/result-statistics/${params.id}/overview`)
            .then(async (data) => {
                
                await commit(SET_STATISTICS, data.data)
                resolve(data);
            })
            .catch((err) => {
                console.error("Get Statistic error : ", err.message)
                reject(err);
            });
        });
    }
}

const mutations = {
    [SET_STATISTICS](state, data) {
        state.statistics = data;
    },
    [SET_STATISTICS_LOADING](state, data) {
        state.isLoading = data;
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}