import Vue from "vue";
import Vuex from "vuex";

import appTypes from "./app-types.module";
import appTypeView from "./app-type-view.module";
import testInvitation from "./test-invitation.module";
import testScenarios from "./test-scenarios.module";
import test from "./test.module";
import auth from "./auth.module";
import leaderboard from "./leaderboard.module";
import leaderboardNew from "./leaderboard-new.module";
import comment from "./comment.module";
import like from "./like.module";
import report from "./report.module";
import hashtag from "./hashtag.module";
import profile from "./profile.module";
import job from "./job.module";
import club from "./club.module";
import track from "./track.module";
import telegram from "./telegram.module";
import statistic from "./statistic.module";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    appTypes,
    appTypeView,
    testInvitation,
    testScenarios,
    test,
    auth,
    leaderboard,
    leaderboardNew,
    job,
    comment,
    like,
    report,
    hashtag,
    profile,
    club,
    telegram,
    track,
    statistic
  },
});
